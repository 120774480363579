<template>
    <div>
        <span :class="'badge badge-pill ' + get_color ">{{health}}</span>
    </div>
</template>

<script>
export default {
  name: "HealthBadge",
  props: {
    color: String,
    health: String
  },
  computed:{
    get_color: function(){
        if(this.color == "Green"){
            return 'badge-success'
        }
        if(this.color == "Grey"){
            return 'badge-secondary'
        }
        if(this.color == "Red"){
            return 'badge-danger'
        }
        if(this.color == "Yellow"){
            return 'badge-warning'
        }

        return 'badge-info'
    }
  }
};
</script>

<style>
</style>