<template>
  <div class="card">
    <div class="card-header p-2">
      <h5 class="m-0">
        <div class="row">
          <div class="col text-left">
            <a :href="'https://' +get_instance_url(instances.Environment.Name) +'prezola.com'" target="_blank">{{ instances.Environment.Name }}</a>
          </div>
          <div class="col text-right">
            <HealthBadge :health="instances.Environment.Health.HealthStatus" :color="instances.Environment.Health.Color" />
          </div>
        </div>
      </h5>
    </div>
    <div class="card-body p-2">
      <div class="card-text">
        <div class="container-flex">
          <div class="row">
            
            <div class="col">
              <div class="row">
                <!-- first 3-->
                <div class="col-3">
                  <div class="row mb-1">
                    <div class="col-1"><b-icon-at /></div>
                    <div class="col-10">{{ instances.Environment.RunningVersion.Version }}</div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-1">
                      <b-icon-bezier2 />
                    </div>
                    <div class="col-10">
                      <a :href="'https://github.com/prezola/prezola/tree/' + instances.Environment.RunningVersion.Branch" target="_blank" >{{ instances.Environment.RunningVersion.Branch }}</a>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-1">
                      <b-icon-box />
                    </div>
                    <div class="col">
                      {{ instances.Environment.RunningVersion.BuildNumber }}
                    </div>
                  </div>
                </div>
                <!-- second 3-->
                <div class="col-3">
                  <div class="row mb-1">
                    <div class="col-1">
                      <b-icon-pencil />
                    </div>
                    <div class="col" v-if="has_issue_id(instances.Environment.RunningVersion.Branch)">
                      <a :href="'https://dev.azure.com/weddingshop/Prezola/_workitems/edit/' + get_issue_id(instances.Environment.RunningVersion.Branch)" target="_blank">AB#{{ get_issue_id(instances.Environment.RunningVersion.Branch) }}</a>
                    </div>
                    <div class="col" v-else>
                      N/A
                    </div>
                  </div>

                  <div class="row mb-1 mt-1">
                    <div class="col-1">
                      <b-icon-calendar3 />
                    </div>
                    <div class="col">
                      {{ instances.Environment.DateUpdated.substring(0, instances.Environment.DateUpdated.length - 9) }}
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-1">
                      <b-icon-info-circle />
                    </div>
                    <div class="col">
                      {{instances.Environment.Health.Status}}
                    </div>
                  </div>

                  <div class="row mb-1" v-if="instances.Environment.Health.Causes.length !== 0">
                    <div class="col-1">
                      <b-icon-exclamation-triangle />
                    </div>
                    <div class="col">
                      {{instances.Environment.Health.Causes.join("\n")}}
                    </div>
                  </div>
                  <div v-else>
                  </div>
                </div>

                <!-- instances -->
                <div class="col-6">
                  <div class="row">
                    <div class="container-flex" v-if="has_instances()">
                      <div v-for="(instance, j) in instances.Environment.Instances" :key="'fs'+j">
                        <div class="row mb-2" >
                          <div class="col-1">
                            <b-icon-cpu />
                          </div>
                          <div class="col-flex">
                            <a :href="'https://eu-west-1.console.aws.amazon.com/ec2/v2/home?region=eu-west-1#InstanceDetails:instanceId='+instance.InstanceId" target="_blank">{{instance.InstanceId}}</a>
                          </div>
                          <div class="col">
                            {{instance.Deployment.Status}}
                          </div>
                          <div class="col-flex">
                            {{instance.Deployment.DeploymentTime.substring(0, instance.Deployment.DeploymentTime.length - 6)}}
                          </div>

                          <div class="col">
                            <HealthBadge :health="instance.HealthStatus" :color="instance.Color"/>
                          </div>
                        </div>
                        <div class="row" v-if="instance.Causes.length !== 0">
                          <div class="col-1">
                            <b-icon-exclamation-triangle />
                          </div>
                          <div class="col">
                            {{ instance.Causes.join('\n') }}
                            <!-- There are no instances. Auto Scaling group desired capacity is set to zero. -->
                          </div>
                        </div>
                        <div class="row" v-else>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HealthBadge from "./HealthBadge.vue";

export default {
  name: "StatusGrid",
  components: {
    HealthBadge,
  },
  props: ["instances"],
  methods: {
    get_instance_url(env_name) {
      if (env_name == "live")
        return ""

      if (env_name == "staging-1"){
        return "staging.";
      }
      
      return env_name.replace("-", "")+".";
    },
    has_issue_id(branch) {
      var r = /\d{5}/;
      if (branch != null) {
        return branch.match(r) != null;
      }
      return false;
    },
    get_issue_id(branch) {
      var r = /\d{5}/;
      if (branch != null) {
        return branch.match(r)[0];
      }
    },
    has_instances() {
      return this.instances.Environment.Instances.length > 0
    }
  },
};
</script>

<style>
a {
  color: #083D77
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}
.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

</style>
