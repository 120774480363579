<template>
  <div id="app">
    <section v-if="errored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <div v-if="loading">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="mb-1" v-for="(environment, j) in data" :key="'fs'+j">
            <StatusCard v-bind:instances="environment" />
          </div>
        </div>
        <div>
          Data retrieved: {{fetched_at}}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StatusCard from './components/StatusCard.vue'
import axios from "axios";

export default {
  name: "App",
  components: {
    StatusCard
  },
  data() {
    return {
      data: [],
      fetched_at: String,
      errored: false,
      loading: true
    };
  },
  created() {
      axios.all([
        axios.get('https://api.prezola.com/dashv/?env_name=live'),
        axios.get('https://api.prezola.com/dashv/?env_name=staging-1'),
        axios.get('https://api.prezola.com/dashv/?env_name=staging-2'),
        axios.get('https://api.prezola.com/dashv/?env_name=staging-3'),
        axios.get('https://api.prezola.com/dashv/?env_name=staging-4'),
        axios.get('https://api.prezola.com/dashv/?env_name=staging-5'),
        axios.get('https://api.prezola.com/dashv/?env_name=matrix')
      ])
      .then(axios.spread((r0, r1, r2, r3, r4, r5, matrix) => {
        this.data = [
          r0.data.Data,
          r1.data.Data,
          r2.data.Data,
          r3.data.Data,
          r4.data.Data,
          r5.data.Data,
          matrix.data.Data];
        this.fetched_at = r1.data.FetchedAt;
      }))
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  }
};
</script>

<style>
body {
  display: flex;
  justify-content: center;
  background: #f6f6f6;
  font-family: "Quicksand";
  line-height: 1.2;
}

.spinner-border {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
}

#app{
    width: 80%;
}
</style>
